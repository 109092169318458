/*eslint-disable*/
import React from "react";

export default {
  en: {
    quote: "Simple, French and Fast",
    author: "Arnaud Weber, Technical Director",
    sector: "Software Company, Real Estate Website Editor",
    interviewees: ["Arnaud Weber, Technical Director"],
    service: "Dynamic Maps",
    geo: "France",
    blocks: [
      {
        title: "La Boîte Immo",
        description: (
          <p>
            <a
              href="https://www.la-boite-immo.com/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              La Boîte Immo
            </a>{" "}
            is a software company working with about 6500 independent real
            estate agencies in France, and more than 35,000 users. It is also a
            publisher of real estate websites, with more than 5200 active client
            websites to this day. Present since the early days of the company,
            Arnaud Weber holds the position of Technical Director.
          </p>
        ),
        img: "clientLogo",
      },
      {
        title: "La Boîte Immo & Jawg",
        description: (
          <span>
            As a publisher of real estate websites, La Boîte Immo integrated
            Jawg Maps on all their client websites. There was a time when La
            Boîte Immo had hesitated to internalize and serve their own mapping
            services. Today, Arnaud testifies{" "}
            <q>
              it turned out that it was more interesting for us to work with
              external technologies such as Jawg
            </q>
            .
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            What reassured him at the beginning of the project is the ease of
            integration of the service{" "}
            <q>extremely smooth and extremely simple</q>. After the setup stage,
            Arnaud never needed to call for support.{" "}
            <q>It worked right away and then we did not have to bother</q>.
          </span>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <span>
            With more than 5,200 client websites and 20 new sites per week, a
            key factor for Arnaud was the fact that he could use the maps
            services on a large number of domain names. The API keys of Jawg can
            do just that and be used on several websites, suiting their needs
            completely.{" "}
            <q>
              It was one of the critical points that made me dismiss a certain
              number of competitors
            </q>{" "}
            says Arnaud.
          </span>
        ),
      },
      {
        title: "Jawg Maps in 3 words?",
        description: "Simple, French and Fast",
      },
    ],
  },
  fr: {
    quote: "Simple, Français et Rapide",
    author: "Arnaud Weber, Directeur Technique",
    sector: "Éditeur de logiciel, Éditeur de sites web d’immobilier",
    interviewees: ["Arnaud Weber, Directeur Technique"],
    service: "Dynamic Maps",
    geo: "France",
    blocks: [
      {
        title: "La Boîte Immo",
        description: (
          <p>
            <a
              href="https://www.la-boite-immo.com/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              La Boîte Immo
            </a>{" "}
            est un éditeur de logiciels qui équipe près de 6500 agences
            immobilières indépendantes en France, soit plus de 35 000
            utilisateurs. C’est aussi un éditeur de sites web d’immobilier, avec
            plus de 5200 sites clients actifs. Présent depuis les débuts de La
            Boite Immo, Arnaud Weber y occupe le poste de Directeur Technique.
          </p>
        ),
        img: "clientLogo",
      },
      {
        title: "La Boîte Immo & Jawg",
        description: (
          <span>
            En tant qu’éditeur de sites web d’immobilier La Boîte Immo intègre
            les cartes Jawg Maps par défaut sur tous leurs sites clients. À une
            époque, La Boîte Immo avait hésité à internaliser chez eux une
            technologie de cartographie. Aujourd’hui, Arnaud témoigne,{" "}
            <q>
              &#160;il s’est avéré qu’il était plus intéressant pour nous de
              passer par des technologies externes telles que Jawg&#160;
            </q>
            .
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            Ce qui le rassure, au début du projet, c’est la facilité
            d’intégration du service{" "}
            <q>&#160;extrêmement fluide et extrêmement simple&#160;</q>. Après
            la mise en place Arnaud n’a jamais eu besoin de faire appel au
            support.{" "}
            <q>
              &#160;ça a marché tout de suite et puis on ne s’est plus posé de
              questions&#160;
            </q>
            .
          </span>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <span>
            Avec plus de 5200 sites webs clients et 20 nouveaux sites par
            semaine, un des points importants pour Arnaud était donc le fait
            qu’il puisse utiliser la cartographie sur un grand nombre de noms de
            domaine. Les clés d’API de Jawg permettant d’être utilisées sur
            plusieurs sites web, cela correspondait complètement à leurs
            besoins.{" "}
            <q>
              &#160;C’était un des points limitants qui ne m’a pas fait retenir
              un certain nombre de concurrents&#160;
            </q>{" "}
            affirme Arnaud.
          </span>
        ),
      },
      {
        title: "Jawg Maps en 3 mots ?",
        description: "Simple, Français et Rapide",
      },
    ],
  },
};
